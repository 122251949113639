/**
 * =============================================================================
 * ************   Divider 分割线   ************
 * =============================================================================
 */

.mdui-divider,
.mdui-divider-light,
.mdui-divider-dark,
.mdui-divider-inset,
.mdui-divider-inset-light,
.mdui-divider-inset-dark {
  height: 1px;
  margin: -1px 0 0 0;
  border: none;
}

.mdui-divider-inset,
.mdui-divider-inset-light,
.mdui-divider-inset-dark {
  margin-left: 72px;
}

.mdui-divider,
.mdui-divider-inset {
  background-color: @color-black-divider;
}

.mdui-divider-light,
.mdui-divider-inset-light {
  background-color: @color-white-divider;
}

.mdui-divider-dark,
.mdui-divider-inset-dark {
  background-color: @color-black-divider;
}

/**
 * =============================================================================
 * ************   Divider dark   ************
 * =============================================================================
 */
.layout-theme({
  .mdui-divider,
  .mdui-divider-inset {
    background-color: @color-white-divider;
  }
});
