/**
 * =============================================================================
 * ************   Dialog alert   ************
 * =============================================================================
 */

 @import './index';

.mdui-dialog-alert {
  max-width: 448px;
}
