/**
 * =============================================================================
 * ************   Grid 网格系统   ************
 * =============================================================================
 */

@_screen-col-1: 8.333333%;
@_screen-col-2: 16.666667%;
@_screen-col-3: 25%;
@_screen-col-4: 33.333333%;
@_screen-col-5: 41.666667%;
@_screen-col-6: 50%;
@_screen-col-7: 58.333333%;
@_screen-col-8: 66.666667%;
@_screen-col-9: 75%;
@_screen-col-10: 83.333333%;
@_screen-col-11: 91.666667%;
@_screen-col-12: 100%;

// 等分列
@_screen-average-1: 100%;
@_screen-average-2: 50%;
@_screen-average-3: 33.333333%;
@_screen-average-4: 25%;
@_screen-average-5: 20%;
@_screen-average-6: 16.666667%;
@_screen-average-7: 14.285714%;
@_screen-average-8: 12.5%;
@_screen-average-9: 11.111111%;
@_screen-average-10: 10%;
@_screen-average-11: 9.090909%;
@_screen-average-12: 8.333333%;

//间隔
@grid-gap-8: 8px;
@grid-gap-16: 16px;
@grid-gap-24: 24px;
@grid-gap-40: 40px;

// 生成列、列偏移
._grid_col_loop(@cols, @screen) when (@cols > 0) {
  ._grid_col_loop((@cols - 1), @screen);

  @widthName: '_screen-col-@{cols}';

  // 列宽度
  .mdui-col-@{screen}-@{cols} {
    float: left;
    width: @@widthName;
  }

  // 列偏移
  .mdui-col-offset-@{screen}-@{cols} {
    margin-left: @@widthName;
  }

  // 等分列
  @averageName: '_screen-average-@{cols}';
  .mdui-row-@{screen}-@{cols} .mdui-col {
    float: left;
    width: @@averageName;
  }
}

.mdui-container,
.mdui-container-fluid {
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding-right: @grid-gap-16 / 2;
  padding-left: @grid-gap-16 / 2;
  &::after{
    display: table;
    clear: both;
    content: '';
  }
}

.mdui-container {
  width: 96%;
  max-width: 1280px;
  @media (min-width: @screen-sm-min) {
    width: 94%;
  }
  @media (min-width: @screen-md-min) {
    width: 92%;
  }
}

.mdui-row,
[class*="mdui-row-"] {
  margin-right: -@grid-gap-16 / 2;
  margin-left: -@grid-gap-16 / 2;
  &::after {
    display: table;
    clear: both;
    content: '';
  }
}

[class*="mdui-col-xs-"],
[class*="mdui-col-sm-"],
[class*="mdui-col-md-"],
[class*="mdui-col-lg-"],
[class*="mdui-col-xl-"],
.mdui-col {
  position: relative;
  box-sizing: border-box;
  min-height: 1px;
  padding-right: @grid-gap-16 / 2;
  padding-left: @grid-gap-16 / 2;
}

/* 取消列间距 */
.mdui-row-gapless {
  .mdui-col,
  [class*="mdui-col-xs-"],
  [class*="mdui-col-sm-"],
  [class*="mdui-col-md-"],
  [class*="mdui-col-lg-"],
  [class*="mdui-col-xl-"] {
    padding-right: 0;
    padding-left: 0;
  }

  // 嵌套元素的内补
  .mdui-row,
  [class*="mdui-row-"] {
    margin-right: 0;
    margin-left: 0;
  }
}

._grid_col_loop(12, ~"xs");

@media (min-width: @screen-sm-min) {
  ._grid_col_loop(12, ~"sm");
}

@media (min-width: @screen-md-min) {
  ._grid_col_loop(12, ~"md");
}

@media (min-width: @screen-lg-min) {
  ._grid_col_loop(12, ~"lg");
}

@media (min-width: @screen-xl-min) {
  ._grid_col_loop(12, ~"xl");
}