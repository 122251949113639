/**
 * =============================================================================
 * ************   Media 媒体工具   ************
 * =============================================================================
 */

/* 响应式图片和视频 */
.mdui-img-fluid,
.mdui-video-fluid {
  .mdui-img-fluid();
}

/* 圆角图片 */
.mdui-img-rounded {
  border-radius: 2px;
}

/* 圆形图片 */
.mdui-img-circle {
  border-radius: 50%;
}

// embed
.mdui-video-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
  }
}
