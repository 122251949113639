.mdui-img-fluid() {
  display: block;
  max-width: 100%;
  height: auto;
}

.mdui-video-fluid() {
  display: block;
  max-width: 100%;
  height: auto;
}
