/**
 * =============================================================================
 * ************   Text Field 文本框   ************
 * =============================================================================
 */

/* 文本框外层 */
.mdui-textfield {
  position: relative;
  padding-top: 16px;
  padding-bottom: 8px;
  overflow: hidden;
}

.mdui-textfield-has-bottom {
  padding-bottom: 28px;
}

/* 输入框 */
.mdui-textfield-input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  margin: 0;
  padding: 8px 0;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-family: inherit;
  line-height: 20px;
  background: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .42);
  border-radius: 0;
  outline: none;
  box-shadow: none;
  transition-timing-function: @animation-curve-default;
  transition-duration: .2s;
  transition-property: border-bottom-color, padding-right, box-shadow;
  appearance: none;
  resize: none;

  &::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.42;
  }

  &:not([disabled]):hover {
    border-bottom: 1px solid rgba(0, 0, 0, .87);
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, .87);
    cursor: pointer;
  }

  &[rows] {
    height: auto !important;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

/* 文本框 label */
.mdui-textfield-label {
  display: block;
  width: 100%;
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
  transform: scale(0.75) translateY(0);
  transform-origin: left;
  transition: all .2s;
  pointer-events: none;
}

/* 表单验证错误提示、帮助文本提示 */
.mdui-textfield-error,
.mdui-textfield-helper {
  position: absolute;
  bottom: 8px;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
  .mdui-text-truncate();
}

.mdui-textfield-error {
  color: rgba(red(@color-red-a400), green(@color-red-a400), blue(@color-red-a400), .87);
  visibility: hidden;
}

.mdui-textfield-helper {
  color: rgba(0, 0, 0, 0.54);
}


/* 表单中的图标
   ========== */
.mdui-textfield {
  .mdui-icon {
    position: absolute;
    bottom: 8px;
    padding: 6px;
    color: @color-black-icon;

    ~ .mdui-textfield-label,
    ~ .mdui-textfield-input,
    ~ .mdui-textfield-error,
    ~ .mdui-textfield-helper,
    ~ .mdui-textfield-flex-wrap {
      /* stylelint-disable-next-line */
      width: calc(~"100% - 56px");
      margin-left: 56px;
    }
  }
}

.mdui-textfield-has-bottom {
  .mdui-icon {
    bottom: 28px;
  }
}

/* 聚焦状态的文本框
   ============= */
.mdui-textfield-focus {

  .mdui-textfield-input,
  .mdui-textfield-input:hover {
    border-bottom-color: @color-default-a700;
    box-shadow: 0 1px 0 0 @color-default-a700;
  }

  .mdui-textfield-label,
  .mdui-icon {
    color: rgba(red(@color-default-a700), green(@color-default-a700), blue(@color-default-a700), .87);
  }
}

/* 含有浮动标签的文本框
   ================ */
.mdui-textfield-floating-label {

  .mdui-textfield-label {
    color: rgba(0, 0, 0, 0.35);
    transform: scale(1) translateY(27px);
  }

  &.mdui-textfield-focus,
  &.mdui-textfield-not-empty {
    .mdui-textfield-label {
      color: rgba(0, 0, 0, 0.65);
      transform: scale(0.75) translateY(0);
    }
  }
  &.mdui-textfield-focus {
    .mdui-textfield-label {
      .mdui-text-color(@color-accent-default-name);
    }
  }
}

/* 可展开文本框，默认向右展开
   ========== */
.mdui-textfield-expandable {
  width: 36px;
  min-height: 36px;
  padding: 1px 0;
  transition: width .3s @animation-curve-default;

  .mdui-icon {
    bottom: 0;
    padding: 0;
  }

  .mdui-textfield-input {
    /* stylelint-disable-next-line */
    width: calc(~"100% - 36px");
    margin-left: 36px;
    padding-right: 0;
  }

  .mdui-textfield-icon {
    position: absolute;
    top: 0;
    left: 0;
  }

  .mdui-textfield-close {
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(0);
  }

  &.mdui-textfield-expanded {
    width: 100%;

    .mdui-textfield-input {
      padding-right: 36px;
    }

    .mdui-textfield-close {
      transform: scale(1);
    }
  }
}

/* 表单验证不通过
   =========== */
.mdui-textfield-invalid,
.mdui-textfield-invalid-html5 {

  .mdui-textfield-input,
  &.mdui-textfield-focus .mdui-textfield-input {
    border-bottom-color: @color-red-a400 !important;
    box-shadow: 0 1px 0 0 @color-red-a400 !important;
  }

  .mdui-textfield-label {
    color: @color-red-a400 !important;
  }

  &.mdui-textfield-floating-label {
    .mdui-textfield-label {
      color: rgba(red(@color-red-a400), green(@color-red-a400), blue(@color-red-a400), 0.35) !important;
    }

    &.mdui-textfield-focus,
    &.mdui-textfield-not-empty {
      .mdui-textfield-label {
        color: @color-red-a400 !important;
      }
    }
  }

  .mdui-textfield-error {
    visibility: visible;

    &+.mdui-textfield-helper {
      visibility: hidden;
    }
  }
}

/* 禁用表单
   ====== */
.mdui-textfield-disabled {
  .mdui-textfield-label,
  .mdui-textfield-input::-webkit-input-placeholder {
    color: @color-black-disabled;
  }

  .mdui-textfield-input {
    color: rgba(0, 0, 0, .42);
    cursor: default;
  }

  .mdui-textfield-input {
    border-bottom: 1px dashed rgba(0, 0, 0, .42);
  }
}

/* 字数统计
   ====== */
.mdui-textfield-counter {
  position: absolute;
  right: 8px;
  bottom: 8px;
  height: 12px;
  color: @color-black-secondary;
  font-size: 12px;
  line-height: 12px;
}


/**
 * =============================================================================
 * ************   Textfield dark   ************
 * =============================================================================
 */
.layout-theme({
  // 输入框颜色
  .mdui-textfield-input {
    color: #fff;
    border-bottom-color: rgba(255, 255, 255, .7);

    &::-webkit-input-placeholder {
      color: rgba(255, 255, 255, .5);
    }

    &:not([disabled]):hover {
      border-bottom-color: rgba(255, 255, 255, 1);
      box-shadow: 0 1px 0 0 rgba(255, 255, 255, 1);
    }
  }

  // 图标颜色
  .mdui-textfield {
    .mdui-icon {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  // 标签颜色
  .mdui-textfield-label {
    color: rgba(255, 255, 255, 0.7);
  }

  .mdui-textfield-floating-label {
    .mdui-textfield-label {
      color: rgba(255, 255, 255, 0.35);
    }
  }

  // 错误文本颜色
  .mdui-textfield-error {
    color: @color-red-a400;
  }

  // 帮助文本颜色
  .mdui-textfield-helper {
    color: rgba(255, 255, 255, 0.7);
  }

  // 字数统计颜色
  .mdui-textfield-counter {
    color: @color-white-secondary;
  }

  // 聚焦状态
  .mdui-textfield-focus {

    .mdui-textfield-input,
    .mdui-textfield-input:hover {
      border-bottom-color: @color-default-a200;
      box-shadow: 0 1px 0 0 @color-default-a200;
    }

    .mdui-textfield-label {
      color: @color-default-a200;
    }
  }

  // 禁用状态
  .mdui-textfield-disabled {
    .mdui-textfield-label,
    .mdui-textfield-input::-webkit-input-placeholder {
      color: @color-white-disabled;
    }

    .mdui-textfield-input {
      color: @color-white-disabled;
    }

    .mdui-textfield-input {
      border-bottom-color: rgba(255, 255, 255, .7);
    }
  }
});


/**
 * =============================================================================
 * ************   Textfield 强调色   ************
 * =============================================================================
 */
& {
  .loop-accent-theme(@counter-color) when (@counter-color > 0) {
    .loop-accent-theme((@counter-color - 1));
    @colorName: extract(@globalAccentColors, @counter-color);

    .mdui-theme-accent-@{colorName} when not (@colorName = null) {

      @colorA700: 'color-@{colorName}-a700';
      @colorA200: 'color-@{colorName}-a200';

      .mdui-textfield-focus {

        .mdui-textfield-input {
          border-bottom-color: @@colorA700;
          box-shadow: 0 1px 0 0 @@colorA700;
        }

        .mdui-textfield-label,
        .mdui-textfield-floating-label.mdui-textfield-focus .mdui-textfield-label,
        .mdui-icon {
          color: rgba(red(@@colorA700), green(@@colorA700), blue(@@colorA700), .87);
        }
      }

      // 暗色主题
      & {
        .dark-mode() {
          .mdui-textfield-focus {

            .mdui-textfield-input {
              border-bottom-color: @@colorA200;
              box-shadow: 0 1px 0 0 @@colorA200;
            }

            .mdui-textfield-label,
            .mdui-textfield-floating-label.mdui-textfield-focus .mdui-textfield-label,
            .mdui-icon {
              color: @@colorA200;
            }
          }
        }

        & when (@globalLayoutDark = true) {
          &.mdui-theme-layout-dark {
            .dark-mode();
          }
        }

        & when (@globalLayoutAuto = true) {
          @media (prefers-color-scheme: dark) {
            &.mdui-theme-layout-auto {
              .dark-mode();
            }
          }
        }
      }
    }
  }
  .loop-accent-theme(length(@globalAccentColors));
}
