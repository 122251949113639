/**
 * =============================================================================
 * ************   List 列表   ************
 * =============================================================================
 */

/* 列表 */
.mdui-list {
  margin: 0;
  padding: 8px 0;
  list-style: none;
  background-color: transparent;

  // 子列表没有上下间距
  .mdui-list {
    padding: 0;
  }

  // 分割线上下边距
  &>.mdui-divider,
  &>.mdui-divider-light,
  &>.mdui-divider-dark,
  &>.mdui-divider-inset,
  &>.mdui-divider-inset-light,
  &>.mdui-divider-inset-dark {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  // 使用副标题时自动添加分割线
  .mdui-subheader,
  .mdui-subheader-inset {
    &::before {
      position: absolute;
      right: 0;
      left: 0;
      display: block;
      height: 1px;
      background-color: @color-black-divider;
      content: ' ';
    }

    margin-top: 8px;

    &:first-child {
      &::before {
        background-color: transparent;
      }

      // 第一个副标题把列表的 padding-top 抵消掉
      margin-top: -8px;
    }
  }
  .mdui-subheader-inset {
    &::before {
      left: 72px;
    }
  }
}

/* 列表项 */
.mdui-list-item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 48px;
  padding: 0 16px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color .3s @animation-curve-default;

  &:hover {
    background-color: @color-hover-black;
  }

  // http://stackoverflow.com/a/31915203
  &::after {
    height: 48px;
    visibility: hidden;
    content: ' ';
  }
}

/* 列表项图标 */
.mdui-list-item-icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  color: @color-black-icon;
}

/* 列表项头像 */
.mdui-list-item-avatar {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: @color-white;
  line-height: 40px;
  text-align: center;
  background-color: @color-grey-400;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

/* 列表项内容 */
.mdui-list-item-content {
  flex-grow: 1;
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

/* 列表项内容的副内容 */
.mdui-list-item-text {
  font-size: 14px;
  opacity: @opacity-black-secondary;
}
.mdui-list-item-title {
  ~ .mdui-list-item-text {
    margin-top: 4px;
  }
}

/* 激活状态的列表项 */
.mdui-list-item-active {
  font-weight: 700;
  background-color: @color-hover-black;

  .mdui-list-item-content {
    font-weight: 700;
  }
  .mdui-list-item-text {
    font-weight: 400;
  }
}

/* 限制文本高度 */
.mdui-list-item-one-line,
.mdui-list-item-two-line,
.mdui-list-item-three-line {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.mdui-list-item-one-line {
  -webkit-line-clamp: 1;
  height: 20px;
}
.mdui-list-item-two-line {
  -webkit-line-clamp: 2;
  height: 40px;
}
.mdui-list-item-three-line {
  -webkit-line-clamp: 3;
  height: 60px;
}

/* 列表项内的元素间添加间距 */
.mdui-list-item-icon {
  ~ .mdui-list-item-content {
    margin-left: 32px;
  }
}
.mdui-checkbox,
.mdui-radio,
.mdui-switch {
  ~ .mdui-list-item-content {
    margin-left: 20px;
  }
}


.mdui-list-item-avatar {
  ~ .mdui-list-item-content {
    margin-left: 16px;
  }
}
.mdui-list-item-content {
  ~ .mdui-list-item-icon,
  ~ .mdui-list-item-avatar,
  ~ .mdui-checkbox,
  ~ .mdui-radio,
  ~ .mdui-switch {
    margin-left: 16px;
  }

  ~ .mdui-checkbox,
  ~ .mdui-radio {
    padding-left: 24px;
  }
}

/* 密集型列表 */
.mdui-list-dense {
  padding: 4px 0;
  font-size: 13px;

  &>.mdui-divider,
  &>.mdui-divider-light,
  &>.mdui-divider-dark,
  &>.mdui-divider-inset,
  &>.mdui-divider-inset-light,
  &>.mdui-divider-inset-dark {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  // 副标题
  .mdui-subheader,
  .mdui-subheader-inset {
    height: 40px;
    margin-top: 4px;
    font-size: 12px;
    line-height: 40px;

    &:first-child {
      margin-top: -4px;
    }
  }

  // 密集型列表项
  .mdui-list-item {
    min-height: 40px;

    &::after {
      height: 40px;
    }
  }

  // 图标
  .mdui-list-item-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
  }

  // 头像
  .mdui-list-item-avatar {
    width: 36px;
    min-width: 36px;
    height: 36px;
    min-height: 36px;
  }

  // 列表项内容
  .mdui-list-item-content {
    padding-top: 11px;
    padding-bottom: 11px;
    font-size: 13px;
    line-height: 18px;
  }
  .mdui-list-item-text {
    font-size: 13px;
  }
  .mdui-list-item-title {
    ~ .mdui-list-item-text {
      margin-top: 2px;
    }
  }

  // 限制文本高度
  .mdui-list-item-one-line {
    height: 18px;
  }
  .mdui-list-item-two-line {
    height: 36px;
  }
  .mdui-list-item-three-line {
    height: 54px;
  }
}


/**
 * =============================================================================
 * ************   List dark   ************
 * =============================================================================
 */
.layout-theme({
  .mdui-list {
    // 副标题的分割线
    .mdui-subheader,
    .mdui-subheader-inset {
      &::before {
        background-color: @color-white-divider;
      }

      &:first-child {
        &::before {
          background-color: transparent;
        }
      }
    }
  }

  // 列表项
  .mdui-list-item {
    color: @color-white-text;

    &:hover {
      background-color: @color-hover-white;
    }
  }

  // 列表项图标
  .mdui-list-item-icon {
    color: @color-white-icon;
  }

  // 列表内容副内容
  .mdui-list-item-text {
    opacity: @opacity-white-secondary;
  }

  // 激活状态的列表项
  .mdui-list-item-active {
    background-color: @color-hover-white;
  }
});

/**
 * ==============================================================================
 * ************   List inner theme   ************
 * ==============================================================================
 */
[class*="mdui-color-"] {
  .mdui-list-item {
    color: inherit;
  }
  .mdui-list-item-icon {
    color: inherit;
  }
}
