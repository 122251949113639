/**
 * =============================================================================
 * ************   Dialog confirm   ************
 * =============================================================================
 */

 @import './index';

.mdui-dialog-confirm {
  max-width: 448px;
}
