/**
 * =============================================================================
 * ************   Shadow 阴影   ************
 * =============================================================================
 */

._md_shadow(@shadow) when (@shadow > -1) {
  ._md_shadow(@shadow - 1);

  .mdui-shadow-@{shadow} {
    .mdui-shadow(@shadow) !important;
  }
}

._md_shadow(24);

/* 鼠标悬浮时加深阴影 */
.mdui-hoverable {
  .mdui-hoverable(8);
}
