/**
 * =============================================================================
 * ************   Dialog prompt   ************
 * =============================================================================
 */

@import '../textfield/index';
@import './index';

.mdui-dialog-prompt {
  max-width: 448px;

  .mdui-textfield {
    padding-top: 0;
  }
}
